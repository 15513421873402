import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Layout} from '../components/layout'
import {media} from '../styles/media-query-template';
import {passLanguage, useLanguage} from '../components/language-context';
import {Section} from '../components/section';
import {FormType, RequestForm} from '../components/request-form';

const HorizontalLayout = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const RequestInfo = styled.div`
  margin: 16px 0;
  width: 100%;

  ${media.medium`
    max-width: 50%;
    flex: 0 0 50%;
  `}
`;

const RequestMethod = styled.div`
  margin-bottom: 24px;
`;

const RequestDemoForm = styled.div`
  width: 100%;

  ${media.medium`
    max-width: 50%;
    flex: 0 0 50%;
  `}
`;

const Bold = styled.span`
  font-weight: bold;
`;

const ForrAddress = styled.div`
  *[dir='rtl'] & {
    text-align: right;
    direction: ltr;
  }
`;

const RequestDemoTime = styled.span`
  *[dir='rtl'] & {
    direction: ltr;
  }
`;

const GetFromPath = () => {
  const regex = /from=(.*)/i;
  const paramStr = window.location.search.substring(1);
  const result = paramStr.match(regex);
  return result === null ? "" : result[1];
};

const RequestADemoPageBase = useLanguage(({location, lang, msg}) => {
  const [fromPath, setFromPath] = useState(null);
  useEffect(() => {
    setFromPath(GetFromPath());
  }, []);

  return (
  <Layout location={location}>
    <Section id='request-demo-form' title={msg('home-demo')} showLine={true} lang={lang}>
      <HorizontalLayout>
        <RequestInfo/>
        <RequestDemoForm>
          <RequestForm formType={FormType.REQUEST_DEMO} fromPath={fromPath} />
        </RequestDemoForm>
      </HorizontalLayout>
    </Section>
  </Layout>
)}
  );

const RequestADemoPage = passLanguage(RequestADemoPageBase);

export default RequestADemoPage;
